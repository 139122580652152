import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { SeoLink } from 'common/ui';
import { useOneLink } from 'entities/one-link';

export const GooglePlayLink = () => {
    const { t, i18n } = useTranslation();
    return (
        <SeoLink
            href={useOneLink('https://indriver.onelink.me/6c5r')}
            target='_blank'
            aria-label={t('download_app_banner_google_play_aria_label')}>
            <Image
                src={`/images/main-page/download-google-${i18n.resolvedLanguage}.svg`}
                alt=''
                width='135'
                height='40'
            />
        </SeoLink>
    );
};
