import { useTranslation } from 'next-i18next';
import React from 'react';
import { Heading, Text } from '@indriver/nova';
import { BidsView } from '../bids-view';
import { Notification } from '../notification';
import { OrderForm } from '../order-form';
import * as Styled from './how-it-works.styles';

export const HowItWorks = () => {
    const { t } = useTranslation('common', { keyPrefix: 'widgets.how_it_works' });
    return (
        <section>
            <Heading type='h2' size='xxl' margin='l'>
                {t('title')}
            </Heading>
            <Styled.Steps>
                <Styled.Step>
                    <Styled.Illustration gravity='bottom'>
                        <Styled.Screen>
                            <OrderForm />
                        </Styled.Screen>
                    </Styled.Illustration>
                    <Styled.Caption>
                        <Styled.Heading type='h2' size='l'>
                            {t('steps.0.title')}
                        </Styled.Heading>
                        <Text weight='medium'>{t('steps.0.text')}</Text>
                    </Styled.Caption>
                </Styled.Step>
                <Styled.Step>
                    <Styled.Illustration gravity='bottom'>
                        <Styled.Screen color='gray'>
                            <BidsView />
                        </Styled.Screen>
                    </Styled.Illustration>
                    <Styled.Caption>
                        <Styled.Heading type='h2' size='l'>
                            {t('steps.1.title')}
                        </Styled.Heading>
                        <Text weight='medium'>{t('steps.1.text')}</Text>
                    </Styled.Caption>
                </Styled.Step>
                <Styled.Step>
                    <Styled.Illustration>
                        <Notification />
                    </Styled.Illustration>
                    <Styled.Caption>
                        <Styled.Heading type='h2' size='l'>
                            {t('steps.2.title')}
                        </Styled.Heading>
                        <Text weight='medium'>{t('steps.2.text')}</Text>
                    </Styled.Caption>
                </Styled.Step>
            </Styled.Steps>
        </section>
    );
};
