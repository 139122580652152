import { css } from '@linaria/core';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import { ChevronRightSmall } from '@indriver/mireska';
import { Cell } from '@indriver/nova';
import { variables } from '@indriver/yrel';
import { ICatalogServiceSearchItem } from 'common/api';
import { ROUTES } from 'common/constants';
import { generatePath, recordEvent } from 'common/lib';
import { CategoryIcon } from 'common/ui';
import { ICityInfo } from 'entities/city';

// FIXME: Cell from nova cannot into dir="rtl" properly
const cellTextAlignFix = "c9hxpkp";
export interface IOptionProps {
  cityInfo: ICityInfo;
  item: ICatalogServiceSearchItem;
}
export const Option = ({
  cityInfo,
  item
}: IOptionProps) => {
  const {
    i18n
  } = useTranslation();
  const dir = i18n.dir();
  const router = useRouter();
  const handleClick = React.useCallback(() => {
    void router.push({
      pathname: generatePath(ROUTES.SERVICE, {
        citySlug: cityInfo.citySlug,
        serviceSlug: item.slug
      })
    });
    recordEvent({
      actionAF: 'masters_customer_search_service_click',
      actionAmplitude: 'masters_customer_search_service_click',
      actionGA: 'masters_customer_search_service_click',
      payload: {
        city_id: cityInfo.id,
        city_name: cityInfo.name,
        country_id: cityInfo.countryId,
        country_name: cityInfo.countryName,
        category_name: item.category_slug,
        service_name: item.slug,
        search_found_service: 'true'
      }
    });
  }, []);
  return <Cell className={cellTextAlignFix} dir={dir} prefix={<CategoryIcon size={24} src={item.category_icon_url} />} subtitle={item.category_title} postfix={<ChevronRightSmall dir={dir} size={24} color={variables['text-and-icon-secondary']} />} onClick={handleClick}>
            {item.name}
        </Cell>;
};

require("./option.linaria.module.css!=!../../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./option.tsx");