import { useTranslation } from 'next-i18next';
import React from 'react';
import { Search } from '@indriver/mireska';
import { ICatalogServiceSearchItem } from 'common/api';
import { searchCatalog } from 'common/api/catalog';
import { recordEvent } from 'common/lib';
import { AutocompleteAsync } from 'common/ui';
import { usePageCity } from 'entities/city';
import { Option } from '../option';
import { ZeroScreen } from '../zero-screen';

export const SearchDesktop = () => {
    const { t, i18n } = useTranslation();
    const dir = i18n.dir();
    const currentCity = usePageCity();

    const sendFocusEvent = React.useCallback(() => {
        recordEvent({
            actionAF: 'masters_customer_search_field_click',
            actionAmplitude: 'masters_customer_search_field_click',
            actionGA: 'masters_customer_search_field_click',
            payload: {
                city_id: currentCity.id,
                city_name: currentCity.name,
                country_id: currentCity.countryId,
                country_name: currentCity.countryName,
            },
        });
    }, [currentCity.countryId, currentCity.countryName, currentCity.id, currentCity.name]);

    const sendQueryViewEvent = React.useCallback(
        (query) => {
            recordEvent({
                actionAF: 'masters_customer_search_query_view',
                actionAmplitude: 'masters_customer_search_query_view',
                actionGA: 'masters_customer_search_query_view',
                payload: {
                    city_id: currentCity.id,
                    city_name: currentCity.name,
                    country_id: currentCity.countryId,
                    country_name: currentCity.countryName,
                    query_text: query,
                    search_results: 'found',
                },
            });
        },
        [currentCity.countryId, currentCity.countryName, currentCity.id, currentCity.name],
    );

    const fetchData = React.useCallback(
        async (query: string, signal: AbortSignal) => {
            if (query.length < 3) {
                return null;
            }

            try {
                const results = await searchCatalog(
                    {
                        locale: i18n.resolvedLanguage,
                        cityId: currentCity.id,
                        query,
                    },
                    signal,
                );

                if (results.length > 0) {
                    sendQueryViewEvent(query);
                }

                return results;
            } catch (error) {
                return null;
            }
        },
        [currentCity.id, i18n.resolvedLanguage, sendQueryViewEvent],
    );

    const renderOption = React.useCallback((item) => <Option item={item} cityInfo={currentCity} />, [currentCity]);

    return (
        <AutocompleteAsync<ICatalogServiceSearchItem>
            dir={dir}
            renderOption={renderOption}
            fetchData={fetchData}
            prefix={<Search dir={dir} size={24} />}
            placeholder={t('search_for_service_placeholder')}
            zeroScreen={<ZeroScreen />}
            onFocus={sendFocusEvent}
        />
    );
};
