import { Trans, useTranslation } from 'next-i18next';
import Image from 'next/image';
import { QRCodeSVG } from 'qrcode.react';
import { isAndroid } from 'react-device-detect';
import { Button, Text } from '@indriver/nova';
import {
    attemptOpenInNewTab,
    recordEvent,
    useBreakpoints,
    usePassUtmParams,
    getWhatsAppSupportLink,
    hasWhatsAppSupport,
    getLandingLink,
} from 'common/lib';
import { SeoLink } from 'common/ui';
import { usePageCity } from 'entities/city';
import { useOneLink } from 'entities/one-link';
import * as Styled from './download-app-section.styles';
import { AppStoreLink } from './ui/app-store-link';
import { GooglePlayLink } from './ui/google-play-link';

interface IProps {
    chooseCard?: boolean;
}

const MobileContainer = ({ chooseCard }: IProps) => {
    const { t } = useTranslation('common');
    const pageCity = usePageCity();
    const landingUrl = useOneLink('https://indriver.onelink.me/zoBK');

    return (
        <Styled.MobileContainerAppSelection chooseCard={chooseCard}>
            <Styled.MobileTextContainer>
                <Styled.TitleText size='xl' type='h2' chooseCard={chooseCard}>
                    {chooseCard
                        ? t('main_page_download_section_title_find_specialist')
                        : t('main_page_download_section_title_become_specialist')}
                </Styled.TitleText>
                <Text size='ml'>
                    {chooseCard
                        ? t('main_page_download_section_description_find_specialist')
                        : t('main_page_download_section_description_become_specialist')}
                </Text>
            </Styled.MobileTextContainer>
            <Styled.BottomContainer
                chooseCard={chooseCard}
                displayImageSection={!chooseCard && hasWhatsAppSupport(pageCity.countryIso)}>
                {chooseCard ? (
                    <>
                        <Image src='/images/main-page/logo-with-rating.svg' alt='' width='112' height='40' />
                        {isAndroid ? <GooglePlayLink /> : <AppStoreLink />}
                    </>
                ) : (
                    <>
                        <Button
                            stretched
                            onClick={() => {
                                attemptOpenInNewTab({ url: landingUrl, focus: true });
                                recordEvent({
                                    actionAF: 'masters_customer_become_specialist_click',
                                    actionAmplitude: 'masters_customer_become_specialist_click',
                                    actionGA: 'masters_customer_become_specialist_click',
                                    payload: {
                                        city_id: pageCity.id,
                                        city_name: pageCity.name,
                                        country_id: pageCity.countryId,
                                        country_name: pageCity.countryName,
                                        came_from: 'banner_main_page',
                                    },
                                });
                            }}>
                            {t('main_page_download_section_sign_in_button')}
                        </Button>
                        {hasWhatsAppSupport(pageCity.countryIso) && (
                            <Styled.MobileWhatsAppText size='xs' weight='medium'>
                                <Trans
                                    i18nKey={'main_page_download_section_sign_in_problems'}
                                    components={[
                                        <SeoLink
                                            key={0}
                                            className={Styled.customLink}
                                            href={getWhatsAppSupportLink(pageCity.countryIso)}
                                            target='_blank'
                                        />,
                                    ]}
                                />
                            </Styled.MobileWhatsAppText>
                        )}
                    </>
                )}
            </Styled.BottomContainer>
        </Styled.MobileContainerAppSelection>
    );
};

const DesktopContainer = ({ chooseCard }: IProps) => {
    const { t } = useTranslation('common');
    const media = useBreakpoints();
    const pageCity = usePageCity();

    const landingUrl = usePassUtmParams(getLandingLink(pageCity.countryIso));
    const qrUrl = useOneLink(chooseCard ? 'https://indriver.onelink.me/6c5r' : 'https://indriver.onelink.me/zoBK');

    return (
        <Styled.DesktopContainerAppSelection chooseCard={chooseCard}>
            <Styled.DesktopContainerInside>
                <Styled.DesktopTopContainer>
                    <Styled.DesktopTextContainer>
                        <div>
                            <Styled.TitleText
                                size={media.isGtM && !media.isGtL ? 'xl' : 'xxl'}
                                type='h2'
                                chooseCard={chooseCard}>
                                {chooseCard
                                    ? t('main_page_download_section_title_find_specialist')
                                    : t('main_page_download_section_title_become_specialist')}
                            </Styled.TitleText>
                            <Text size='ml'>
                                {chooseCard
                                    ? t('main_page_download_section_description_find_specialist')
                                    : t('main_page_download_section_description_become_specialist')}
                            </Text>
                        </div>
                        {media.isGtM ? null : (
                            <Styled.LogoAndLinkContainer>
                                {chooseCard ? (
                                    <Image
                                        src='/images/main-page/logo-with-rating.svg'
                                        alt=''
                                        width='112'
                                        height='40'
                                    />
                                ) : (
                                    <Button
                                        stretched
                                        onClick={() => {
                                            attemptOpenInNewTab({ url: landingUrl, focus: true });
                                            recordEvent({
                                                actionAF: 'masters_customer_become_specialist_click',
                                                actionAmplitude: 'masters_customer_become_specialist_click',
                                                actionGA: 'masters_customer_become_specialist_click',
                                                payload: {
                                                    city_id: pageCity.id,
                                                    city_name: pageCity.name,
                                                    country_id: pageCity.countryId,
                                                    country_name: pageCity.countryName,
                                                    came_from: 'banner_main_page',
                                                },
                                            });
                                        }}>
                                        {t('main_page_download_section_sign_in_button')}
                                    </Button>
                                )}
                                <Styled.LinkContainer>
                                    <GooglePlayLink />
                                    <AppStoreLink />
                                </Styled.LinkContainer>
                            </Styled.LogoAndLinkContainer>
                        )}
                    </Styled.DesktopTextContainer>
                    <Styled.DesktopQRContainer>
                        {!chooseCard && hasWhatsAppSupport(pageCity.countryIso) && (
                            <Styled.DesktopWhatsAppNotice>
                                <Text size='xxs'>
                                    <Trans
                                        i18nKey={'main_page_download_section_sign_in_problems'}
                                        components={[
                                            <SeoLink
                                                key={0}
                                                className={Styled.customLink}
                                                href={getWhatsAppSupportLink(pageCity.countryIso)}
                                                target='_blank'
                                            />,
                                        ]}
                                    />
                                </Text>
                            </Styled.DesktopWhatsAppNotice>
                        )}
                        <QRCodeSVG
                            value={qrUrl}
                            size={156}
                            bgColor='transparent'
                            imageSettings={{
                                src: '/images/qr/logo.svg',
                                width: 40,
                                height: 40,
                                excavate: true,
                            }}
                        />
                        <Styled.TitleQRText size='xxs' chooseCard={chooseCard}>
                            {t('main_page_download_section_qr_title')}
                        </Styled.TitleQRText>
                    </Styled.DesktopQRContainer>
                </Styled.DesktopTopContainer>
                <Styled.DesktopBottomInfoContainer>
                    <GooglePlayLink />
                    <AppStoreLink />
                </Styled.DesktopBottomInfoContainer>
            </Styled.DesktopContainerInside>
        </Styled.DesktopContainerAppSelection>
    );
};

export const DownloadAppSection = () => {
    const media = useBreakpoints();

    return (
        <section>
            {media.isGtS ? (
                <Styled.DesktopWrapper>
                    <DesktopContainer chooseCard />
                    <DesktopContainer />
                </Styled.DesktopWrapper>
            ) : (
                <Styled.MobileWrapper>
                    <MobileContainer chooseCard />
                    <MobileContainer />
                </Styled.MobileWrapper>
            )}
        </section>
    );
};
