import { Trans, useTranslation } from 'next-i18next';
import { Bid } from '../bid';
import { SnackBar } from '../snack-bar';
import * as Styled from './bids-view.styles';

export const BidsView = () => {
    const { t } = useTranslation('common', { keyPrefix: 'widgets.bids_slide' });
    return (
        <Styled.Box>
            <Styled.SnackBar>
                <SnackBar>
                    <Trans t={t} i18nKey='snackbar' components={{ br: <br /> }} />
                </SnackBar>
            </Styled.SnackBar>
            <Bid
                price={t('bids.0.price')}
                name={t('bids.0.name')}
                avatar={t('bids.0.avatar')}
                reviews={t('bids.0.reviews')}
            />
            <Bid
                price={t('bids.1.price')}
                name={t('bids.1.name')}
                avatar={t('bids.1.avatar')}
                reviews={t('bids.1.reviews')}
            />
        </Styled.Box>
    );
};
