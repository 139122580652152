import { useTranslation } from 'next-i18next';
import { FavFilled, PhoneAccept, Whatsapp } from '@indriver/mireska';
import { Button } from '@indriver/nova';
import { variables } from '@indriver/yrel';
import * as Styled from './bid.styles';

export interface ITaskerProps {
    price: string;
    name: string;
    rating?: string;
    reviews: number;
    avatar: string;
}

export const Bid = ({ price, name, rating = '5.0', reviews, avatar }: ITaskerProps) => {
    const { t } = useTranslation('common', { keyPrefix: 'widgets.bids_slide' });
    return (
        <Styled.Box>
            <Styled.Header>
                <Styled.Price type='h3' size='l' fontType='marketing'>
                    {price}
                </Styled.Price>
                <Styled.Info>
                    {name}
                    <Styled.Rating>
                        <FavFilled size={14} className={Styled.fav} />
                        {rating}
                    </Styled.Rating>
                    <Styled.Reviews color={variables['text-and-icon-secondary']}>({reviews})</Styled.Reviews>
                </Styled.Info>
                <Styled.Avatar size='l' image={`/public/images/avatars/${avatar}`} />
            </Styled.Header>
            <Styled.Skeleton />
            <Styled.Buttons>
                <Button size='xs'>{t('accept')}</Button>
                <Button size='xs' postfix={<PhoneAccept size={18} />} design='secondary' />
                <Button size='xs' postfix={<Whatsapp size={18} />} design='secondary' />
            </Styled.Buttons>
        </Styled.Box>
    );
};
