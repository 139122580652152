import { useTranslation } from 'next-i18next';
import { ClearFilled, Wrench } from '@indriver/mireska';
import { Heading } from '@indriver/nova';
import { variables } from '@indriver/yrel';
import * as Styled from './order-form.styles';

export const OrderForm = () => {
    const { t } = useTranslation('common', { keyPrefix: 'widgets.order_form_slide' });
    return (
        <Styled.Box>
            <Styled.Bar />
            <Styled.ServiceName>
                <Wrench size={18} />
                {t('service')}
            </Styled.ServiceName>
            <Heading type='h3' size='l' fontType='marketing'>
                {t('step_label')}
            </Heading>
            <Styled.Textarea>
                <Styled.FieldLabel>{t('field_label')}</Styled.FieldLabel>
                <Styled.FieldValue>{t('field_value')}</Styled.FieldValue>
                <Styled.Clear>
                    <ClearFilled size={22} color={variables['text-and-icon-disabled']} />
                </Styled.Clear>
            </Styled.Textarea>
            <Styled.Hint>{t('hint')}</Styled.Hint>
        </Styled.Box>
    );
};
