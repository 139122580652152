import Link from 'next/link';
import React from 'react';
import { Logo } from '@indriver/nova';
import { ROUTES } from 'common/constants';
import { generatePath } from 'common/lib';
import { InvisibleLink } from 'common/ui';
import { usePageCity } from 'entities/city';
import * as Styled from './footer-layout.styles';

interface IFooterProps {
    header: React.ReactNode;
    content: React.ReactNode;
    store: React.ReactNode;
    footer: React.ReactNode;
    copyright: React.ReactNode;
}

export const FooterLayout = ({ header, content, store, footer, copyright }: IFooterProps) => {
    const currentCity = usePageCity();
    return (
        <Styled.Box>
            <Styled.Header withContent={!!header}>
                <Link href={generatePath(ROUTES.CITY, { citySlug: currentCity.citySlug })} passHref>
                    <InvisibleLink>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        <Logo design='icon-color' width={55} height={55} />
                    </InvisibleLink>
                </Link>
                {header}
            </Styled.Header>
            <Styled.Content>
                <Styled.Links>{content}</Styled.Links>
                <Styled.Store>{store}</Styled.Store>
            </Styled.Content>
            <Styled.Footer>
                <Styled.FooterSlot>{footer}</Styled.FooterSlot>
                <Styled.Copyright>{copyright}</Styled.Copyright>
            </Styled.Footer>
        </Styled.Box>
    );
};
