import { useTranslation } from 'next-i18next';
import { SeoLink } from 'common/ui';
import { PlayStoreOnColor } from 'common/ui/icons';
import { useOneLink } from 'entities/one-link';

export const GooglePlayLink = () => {
    const { t } = useTranslation();
    return (
        <SeoLink
            href={useOneLink('https://indriver.onelink.me/6c5r')}
            target='_blank'
            aria-label={t('download_app_banner_google_play_aria_label')}>
            <PlayStoreOnColor width={162} height={48} />
        </SeoLink>
    );
};
