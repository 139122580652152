import { useTranslation } from 'next-i18next';
import React from 'react';
import { FavFilled } from '@indriver/mireska';
import { Text } from '@indriver/nova';
import * as Styled from './notification.styles';

export const Notification = () => {
    const { t } = useTranslation('common', { keyPrefix: 'widgets.notification_slide' });

    return (
        <Styled.Box>
            <Styled.Avatar size='s' image={`/public/images/avatars/${t('avatar')}`} />
            <Styled.RatingBar>
                <FavFilled size={36} color='#ffc13d' />
                <FavFilled size={36} color='#ffc13d' />
                <FavFilled size={36} color='#ffc13d' />
                <FavFilled size={36} color='#ffc13d' />
                <FavFilled size={36} color='#ffc13d' />
            </Styled.RatingBar>
            <Text size='s'>{t('text')}</Text>
        </Styled.Box>
    );
};
