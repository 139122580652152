const deepLinkByLink = {
    'https://indriver.onelink.me/6c5r': 'https://indriver.com/app/super_services',
    'https://indriver.onelink.me/zoBK': 'indriver://open/any/super_services?mode=tasker&route=categories/',
} as const;

export type ValidOneLinkURL = keyof typeof deepLinkByLink;

export const getConfig = (oneLinkURL: ValidOneLinkURL) => ({
    oneLinkURL,
    webReferrer: 'af_sub3',
    afParameters: {
        ad: { keys: ['utm_term'], defaultValue: 'any_term' },
        adSet: { keys: ['utm_content'], defaultValue: 'any_content' },
        afSub1: { keys: ['fbclid'] },
        campaign: { keys: ['utm_campaign'], defaultValue: 'any_campaign' },
        channel: { keys: ['utm_medium'], defaultValue: 'any_medium' },
        googleClickIdKey: 'af_sub2',
        mediaSource: { keys: ['utm_source'], defaultValue: 'any_source' },
        afCustom: [
            { paramKey: 'af_dp', defaultValue: encodeURIComponent(deepLinkByLink[oneLinkURL]) },
            { paramKey: 'af_ip', keys: ['client_ip_address'], defaultValue: 'client_ip_address' },
            { paramKey: 'af_ss_ui', defaultValue: 'true' },
            { paramKey: 'af_ua', keys: ['client_user_agent'], defaultValue: 'client_user_agent' },
            { paramKey: 'is_retargeting', defaultValue: 'true' },
        ],
        deepLinkValue: { defaultValue: encodeURIComponent(deepLinkByLink[oneLinkURL]) },
    },
});
