import React from 'react';
import { InfoOutline, People2 } from '@indriver/mireska';
import { RoundIcon } from '@indriver/nova';
import * as Styled from './snack-bar.styles';

export interface ISnackBarProps {
    children: React.ReactNode;
}

export const SnackBar = ({ children }: ISnackBarProps) => (
    <Styled.Box>
        <RoundIcon design='earth' size='xs' content={<People2 size={14} />} />
        <Styled.Text>{children}</Styled.Text>
        <InfoOutline size={20} />
    </Styled.Box>
);
