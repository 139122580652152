import { useTranslation } from 'next-i18next';
import React from 'react';
import { attemptOpenInNewTab, recordEvent, usePassUtmParams, getLandingLink } from 'common/lib';
import { usePageCity } from 'entities/city';
import * as Styled from './become-master-banner.styles';

export const BecomeMasterBanner = () => {
    const { t } = useTranslation('common', { keyPrefix: 'features.become_master_banner' });
    const pageCity = usePageCity();
    const landingUrl = usePassUtmParams(getLandingLink(pageCity.countryIso));
    const handleButtonClick = () => {
        attemptOpenInNewTab({ url: landingUrl, focus: true });
        recordEvent({
            actionAF: 'masters_customer_become_specialist_click',
            actionAmplitude: 'masters_customer_become_specialist_click',
            actionGA: 'masters_customer_become_specialist_click',
            payload: {
                city_id: pageCity.id,
                city_name: pageCity.name,
                country_id: pageCity.countryId,
                country_name: pageCity.countryName,
                came_from: 'banner',
            },
        });
    };

    return (
        <Styled.Box onClick={handleButtonClick}>
            <Styled.Title>{t('title')}</Styled.Title>
            <Styled.Subtitle>{t('subtitle')}</Styled.Subtitle>
        </Styled.Box>
    );
};
