import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { useBoolean } from 'usehooks-ts';
import { Earth, PinOutline } from '@indriver/mireska';
import { ROUTES } from 'common/constants';
import { useBreakpoints, useAuthorizedUser, formatLocaleName } from 'common/lib';
import { usePageCity, useUserCity } from 'entities/city';
import { useTerms, useUserLocaleMutation } from 'entities/user';
import { BecomeMasterBanner } from 'features/become-master-banner';
import { CitySwitcher } from 'features/city-switcher';
import { DesktopLanguageSwitcher, MobileLanguageSwitcher } from 'features/language-switcher';
import * as Styled from './footer.styles';
import { AppStoreLink } from './ui/app-store-link';
import { DownloadAppButton } from './ui/download-app-button';
import { FooterLayout } from './ui/footer-layout';
import { FooterLink } from './ui/footer-link';
import { GooglePlayLink } from './ui/google-play-link';
import { LinksSection, LinksSectionHeading } from './ui/links-section';

interface IFooterProps {
    header?: React.ReactNode;
}

export const Footer = ({ header }: IFooterProps) => {
    const media = useBreakpoints();

    const { locale = '' } = useRouter();
    const { t, i18n } = useTranslation('common', { keyPrefix: 'widgets.footer' });
    const dir = i18n.dir();
    const visibility = useBoolean();
    const pageCity = usePageCity();
    const userCity = useUserCity();
    const isGeoConfirmDialogOpen = userCity?.id !== pageCity.id;
    const { data: terms } = useTerms({ countryIso: pageCity.countryIso, locale: i18n.resolvedLanguage });

    const isAuthorizedUser = useAuthorizedUser();
    const userLocaleMutation = useUserLocaleMutation();
    const updateUserLang = async (nextLocale: string) => {
        if (isAuthorizedUser) {
            await userLocaleMutation.mutateAsync(nextLocale);
        }
    };

    return (
        <>
            <BecomeMasterBanner />
            <FooterLayout
                header={header}
                content={
                    <>
                        <LinksSection>
                            <LinksSectionHeading>{t('company_links.title')}</LinksSectionHeading>
                            <Link href={{ pathname: ROUTES.CONTACTS }} passHref>
                                <FooterLink>{t('company_links.contacts')}</FooterLink>
                            </Link>
                            <Link href={{ pathname: ROUTES.ABOUT_US }} passHref>
                                <FooterLink>{t('company_links.about_us')}</FooterLink>
                            </Link>
                        </LinksSection>
                        {terms && (terms.privacy_policy_url || terms.public_offer_url) && (
                            <LinksSection>
                                <LinksSectionHeading>{t('legal_links.title')}</LinksSectionHeading>
                                {terms.public_offer_url && (
                                    <FooterLink href={terms.public_offer_url}>{t('legal_links.tos')}</FooterLink>
                                )}
                                {terms.privacy_policy_url && (
                                    <FooterLink href={terms.privacy_policy_url}>
                                        {t('legal_links.privacy_policy')}
                                    </FooterLink>
                                )}
                            </LinksSection>
                        )}
                        <LinksSection>
                            <LinksSectionHeading>{t('media_links.title')}</LinksSectionHeading>
                            <FooterLink href='https://instagram.com/indrive'>{t('media_links.instagram')}</FooterLink>
                            <FooterLink href='https://facebook.com/indrive'>{t('media_links.facebook')}</FooterLink>
                        </LinksSection>
                    </>
                }
                store={
                    media.isGtM ? (
                        <>
                            <AppStoreLink />
                            <GooglePlayLink />
                        </>
                    ) : (
                        <DownloadAppButton />
                    )
                }
                footer={
                    <>
                        <CitySwitcher
                            dir={dir}
                            orientation='top-start'
                            offset={{ mainAxis: 8 }}
                            width='25rem'
                            visibility={visibility}>
                            <Styled.Button
                                dir={dir}
                                design='ghost'
                                prefix={<PinOutline size={24} />}
                                aria-label={t('city_switcher.aria_label')}
                                onClick={() => {
                                    if (!isGeoConfirmDialogOpen) {
                                        visibility.toggle();
                                    }
                                }}>
                                {pageCity.name}
                            </Styled.Button>
                        </CitySwitcher>
                        {media.isGtS ? (
                            <DesktopLanguageSwitcher
                                dir={dir}
                                offset={{ mainAxis: 8 }}
                                orientation='top-start'
                                onChange={updateUserLang}>
                                <Styled.Button
                                    dir={dir}
                                    design='ghost'
                                    prefix={<Earth size={24} />}
                                    aria-label={t('language_switcher.aria_label')}>
                                    {formatLocaleName(locale)}
                                </Styled.Button>
                            </DesktopLanguageSwitcher>
                        ) : (
                            <MobileLanguageSwitcher onChange={updateUserLang}>
                                <Styled.Button
                                    dir={dir}
                                    design='ghost'
                                    prefix={<Earth size={24} />}
                                    aria-label={t('language_switcher.aria_label')}>
                                    {formatLocaleName(locale)}
                                </Styled.Button>
                            </MobileLanguageSwitcher>
                        )}
                    </>
                }
                copyright={t('copyright')}
            />
        </>
    );
};
