import { useTranslation } from 'next-i18next';
import { MouseEventHandler } from 'react';
import { Button } from '@indriver/nova';
import { useOneLink } from 'entities/one-link';

interface IDownloadAppButtonProps {
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const DownloadAppButton = ({ onClick }: IDownloadAppButtonProps) => {
    const { t } = useTranslation('common', { keyPrefix: 'features.download_app_button' });

    const href = useOneLink('https://indriver.onelink.me/6c5r');

    const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        onClick?.(e);
        window.open(href, '_blank');
    };

    return (
        <Button size='l' stretched role='link' aria-label={t('aria_label')} data-href={href} onClick={handleClick}>
            {t('title')}
        </Button>
    );
};
