import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import React from 'react';
import { Text } from '@indriver/nova';
import { variables } from '@indriver/yrel';
import * as Styled from './zero-screen.styles';

export const ZeroScreen = () => {
    const { t } = useTranslation();
    return (
        <Styled.Box>
            <Image src='/images/main-page/tninking.svg' alt='' width='160' height='160' />
            <Styled.Text>
                <Text size='s' color={variables['text-and-icon-secondary']}>
                    {t('not_find_such_service')}
                </Text>
            </Styled.Text>
        </Styled.Box>
    );
};
